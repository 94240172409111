.ui.fluid.selection.dropdown.dropdown-component,
.ui.fluid.selection.dropdown.dropdown-version {
  z-index: 181;
}
.dropdown-component-wrapper,
.dropdown-version-wrapper {
  width: 20%;
  margin-right: var(--spacing-sm);
}
.selector-wrapper {
  display: flex;
}
