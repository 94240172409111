:root {
  --main-font-familily: 'graphik';
}

@font-face {
  font-family: var(--main-font-familily);
  src: url('../fonts/graphik/Graphik-Regular.otf') format('opentype');
}
@font-face {
  font-family: var(--main-font-familily);
  font-style: italic;
  src: url('../fonts/graphik/Graphik-RegularItalic.otf') format('opentype');
}
@font-face {
  font-family: var(--main-font-familily);
  font-weight: bold;
  src: url('../fonts/graphik/Graphik-Semibold.otf') format('opentype');
}

@media print {
  .ui.toggle.button.ui.button.toggle,
  .breadcrumbs-wrapper,
  .title-simulation-result-page,
  .full-navigation-component,
  .simulatie-row,
  .ui.one.column.grid,
  button.ui.button.primary,
  .simulation-config-wrapper h1,
  .simulation-config-wrapper a
  {
    display: none;
  }

  @page {
    margin: 0;
  }
  html {
    padding: 9% 4%;
  }
  h1,
  h2 {
    margin-top: 9%;
  }
  p,
  ul,
  table,
  img {
    margin-bottom: 20px;
  }
  .recharts-wrapper {
    margin: 30px 0;
  }
  .ui.table td {
    padding-top: 7px;
    padding-bottom: 5px;
  }
  svg.recharts-surface {
    width: calc(100%) !important;
    margin: 0 auto;
  }
  hr {
    page-break-after: always;
  }
  /* no repeating table headers */
  thead {
    display: table-row-group;
  }
  .padding-contentw {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
}

.recharts-wrapper {
  overflow: visible;
}

.text-titles {
  font-family: var(--main-font-familily);
  font-weight: bold;
}

.p-default {
  font-family: var(--main-font-familily);
  font-size: 16px;
  line-height: 26px;
  padding: 5px 5px 5px 5px;
}

.effort-table {
  margin-bottom: 20px !important;
}

.horizontal {
  border: 0;
  border-top: 1px solid #eee;
  margin: 40px 0 20px 0;
}
.horizontal.first {
  border: 0;
  border-top: 2px solid #eee;
  margin-top: 19px;
}
.horizontal.second {
  margin-bottom: 90px;
}

.ui.table td {
  padding: 7px 5px 5px;
}

.user-story-epic-table {
  font-family: var(--main-font-familily);
  font-size: 10px;
  padding-left: 18px;
  margin: 0px;
}

.ui.table td.positive,
.ui.table tr.positive {
  color: black !important;
  background-color: #dff0d8 !important;
}
.ui.table td.warning,
.ui.table tr.warning {
  color: black !important;
  background-color: #fcf8e3 !important;
}
.ui.table td.negative,
.ui.table tr.negative {
  color: black !important;
  background-color: #f2dede !important;
}

body {
  font-family: var(--main-font-familily);
}
p,
h2,
h1,
h3,
b,
li {
  font-family: var(--main-font-familily);
  color: #000 !important;
}

.description-epic-table {
  font-family: var(--main-font-familily);
  font-size: 10px;
  background: #f5f5f5;
  border-radius: 2px;
  padding: 2px 5px;
  margin-left: 16px;
  margin-bottom: 4px;
  margin-right: 20px;
  border: 1px solid #eaeaea;
  display: table;
}
.ak-renderer-document {
  font-family: var(--main-font-familily);
  font-size: 10px;
}

.title-simulation-result-page {
  color: #000;
  font-weight: 600;
  margin-bottom: 5%;
  font-size: 30px;
  font-family: var(--main-font-familily);
}

.discount-wrapper {
  margin-top: 32px;
  font-size: 16px;
  border-radius: 4px;
  background: aliceblue;
  padding: 15px;
  border: 1px solid;
}

.old-price {
  text-decoration: line-through;
}
.new-price {
  font-weight: 600;
}
