/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */
:root {
  /* vp-style: 6px-14px @ 320px-1446px */
  --spacing-xs: calc(3.7px + 0.71vw);
  /* vp-style: 12px-26px @ 320px-1446px */
  --spacing-sm: calc(8px + 1.24vw);
  /* vp-style: 22px-44px @ 320px-1446px */
  --spacing-md: calc(15.7px + 1.95vw);
  /* vp-style: 32px-60px @ 320px-1446px */
  --spacing-lg: calc(24px + 2.49vw);
  /* vp-style: 60px-130px @ 320px-1446px */
  --spacing-xl: calc(40.1px + 6.22vw);

  --header-height: 117px;
}
@media (min-width: 1446px) {
  :root {
    --spacing-xs: 14px;
    --spacing-sm: 26px;
    --spacing-md: 44px;
    --spacing-lg: 60px;
    --spacing-xl: 130px;
  }
}
