.user-story-container {
  background-color: red;
}
.user-story {
  background-color: blue;
}
/*i.trash-delete-issue{*/
/*    cursor: pointer;*/
/*    display:none!important;*/
/*    float: right;*/
/*}*/
/*td:hover .trash-delete-issue {*/
/*    display: inline-block!important;*/
/*}*/

/*.trash-delete-issue:hover,*/
.icon-external-link:hover {
  font-size: 15px;
  text-align: center;
  align-items: center;
  display: inline-block !important;
}
.handsontableInput {
  border: 2px #5292f7 solid;
  /* box-shadow: 0 2px 6px 2px rgba(60,64,67,.15); */
}
.css-eaycls {
  background-color: white;
}
.handsontable .htFiltersActive .changeType {
  color: rgb(75, 137, 255) !important;
  border: 1px solid rgb(75, 137, 255) !important;
}
.icon-external-link {
  cursor: pointer;
  float: right;
  margin-left: 5px;
}

.hot-container {
  height: calc(100vh - 120px);
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}
.flag-wrapper {
  width: 20vw;
}

/* ----------------------------------------------------- */
/* Status gedeelte */

.cell-container-status {
  text-align: center;
  border-radius: 5px;
}

.cell-container-status.blue-gray {
  background-color: rgb(223, 225, 230);
}
.cell-container-status.yellow {
  background-color: rgb(0, 82, 204);
  color: white;
}
.cell-container-status.green {
  background-color: rgb(0, 135, 90);
  color: white;
}
