a:hover {
  text-decoration: none;
}
.css-1hpn4bw::before{
  display:none!important
}
.css-xdwmn0::before{
  display:none!important
}

.project-title {
  padding-left: 24px;
}